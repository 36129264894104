@use "../../styles/mixins" as *;
@include layer(ui) {
    @keyframes animation-tooltip-content {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }

    .overlay {
        padding: var(--c-tooltip-padding);
        background-color: var(--s-color-surface-secondary);
        border-radius: var(--s-border-radius-background-small);
        border-width: var(--c-tooltip-border-width);
        border-color: var(--c-tooltip-border-color);
        border-style: solid;
        animation: animation-tooltip-content var(--g-duration-xshort) ease;
        max-width: 300px;
        box-shadow: var(--c-tooltip-shadow);
        z-index: 10;
    }

    .overlayArrow {
        fill: var(--s-color-surface-secondary);

        & > path:first-of-type {
            stroke: var(--c-tooltip-border-color);
            stroke-width: var(--c-tooltip-arrow-stroke-width);
        }

        @media (forced-colors: active) {
            fill: currentColor;

            & > path:first-of-type {
                stroke: currentColor;
            }
        }
    }
}
