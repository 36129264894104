.terms {
    margin-bottom: var(--g-spacing-md);
    height: 250px;
    overflow: auto;
    border: var(--g-border-width-xs) solid var(--s-color-field-border-default);
    border-radius: var(--s-border-radius-background-standard);
    padding: var(--g-spacing-md);
    &:focus-visible {
        outline: var(--g-border-width-sm) solid
            var(--s-color-brand-primary-standard);
    }
}
