.wrapper {
    display: flex;
    flex-direction: column;
    gap: var(--g-spacing-md);
    margin-bottom: var(--g-spacing-xl);
}

.inline--70-30 {
    display: grid;
    gap: var(--g-spacing-md);
    align-items: start;
    grid-template-columns: 1fr minmax(150px, 30%);
}
