@use "@wojo/ui/mixins" as *;

.info-card-request {
    display: none;

    @include breakpoint-sm {
        display: block;
        margin-bottom: var(--g-spacing-lg);
        margin-top: var(--g-spacing-md);
    }
}

.info-card-list {
    list-style-type: none;
    width: fit-content;
    margin: 0 auto;
    padding: 0 0 var(--g-spacing-md) 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--g-spacing-sm);

    li {
        color: currentColor;
        display: inline-flex;
        align-items: flex-start;
        gap: var(--g-spacing-sm);
        text-align: left;

        @include breakpoint-md {
            align-items: center;
        }
    }
}
