@use "../../../styles/mixins" as *;
@use "../../../styles/mixins" as *;
@include layer(ui) {
    .wrapper {
        display: grid;
        grid-template-columns: min-content 1fr;
        align-items: start;
        gap: var(--g-spacing-sm);

        // We need the double class selector to increase specificity because this field also has
        // `Text.Body` and so the order of color application can be a random otherwise.
        &.basic {
            &--alert {
                color: var(--s-color-alert-standard);
            }

            &--success,
            &--confirmed {
                color: var(--s-color-success-standard);
            }

            &--info {
                color: var(--s-color-info-standard);
            }
        }
    }
}
