@use "@wojo/ui/mixins" as *;

.account-page-title {
    padding-bottom: var(--g-spacing-md);
    @include breakpoint-lg {
        padding-bottom: var(--g-spacing-lg);
    }
}

.zendesk-title {
    padding-bottom: var(--g-spacing-md);
}
.content-group {
    display: flex;
    flex-direction: column;
    gap: var(--g-spacing-xl);

    @include breakpoint-lg {
        gap: var(--g-spacing-lg);
    }
}
