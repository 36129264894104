@use "@wojo/ui/mixins" as *;

.menu {
    background-color: var(--c-footer-background-color);
}

.top {
    display: flex;
    flex-direction: column;
    padding-top: var(--g-spacing-xl);

    @include for-tablet-up {
        padding-top: var(--g-spacing-xxl);
        padding-bottom: var(--g-spacing-xl);
    }
}

.top-content {
    margin-inline: 0;
}

.main-brand {
    display: flex;
    margin-bottom: var(--g-spacing-xl);
    width: fit-content;
    &:focus-visible {
        outline: var(--s-color-icon-inverse) solid var(--g-border-width-sm);
        outline-offset: var(--g-spacing-xs);
        border-radius: var(--g-border-radius-xs);
    }

    img {
        height: 34px;
        max-width: 100%;
    }
}

.family-brand-desktop {
    display: none;

    @include breakpoint-xl {
        display: flex;
    }
}

.family-brand-mobile {
    display: flex;

    @include breakpoint-xl {
        display: none;
    }
}

.top-nav {
    list-style-type: none;
    margin-bottom: var(--g-spacing-md);

    @include for-tablet-up {
        margin-bottom: var(--g-spacing-xl);
    }
}

.market-and-socials {
    @include for-tablet-up {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

.market-section {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    @include for-tablet-up {
        margin-bottom: 0;
    }
}

.market-icon {
    color: var(--s-color-icon-inverse);

    img {
        min-height: 22px;
        width: auto;
    }
}

.market-text {
    color: var(--s-color-text-inverse);
}

.market-link {
    color: var(--s-color-text-inverse);
    text-decoration: none;

    &:focus-visible {
        text-decoration: underline;
        outline: var(--s-color-text-inverse) solid var(--g-border-width-sm);
        border-radius: var(--s-border-radius-interactive-focus);
    }

    &:hover,
    &:active {
        text-decoration: underline;
        transition-duration: var(--g-duration-xshort);
    }
}

.socials {
    display: flex;
    gap: var(--g-spacing-md);
    margin: var(--g-spacing-xl) 0 var(--g-spacing-lg);

    @include for-tablet-up {
        margin-top: 0;
        margin-bottom: 0;
        align-self: flex-end;
    }
}

.social-button {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    text-decoration: none;
    height: 36px;
    width: 36px;
    color: var(--s-color-icon-inverse);

    img {
        min-height: 24px;
        min-width: 24px;
    }

    &:focus-visible {
        outline: var(--s-color-icon-inverse) solid var(--g-border-width-sm);
        border-radius: 36px;
    }
}

.border {
    border-top: var(--g-border-width-xs) solid var(--g-color-white);
    opacity: 0.2;
    margin: 0;
}

.bottom {
    margin-top: var(--g-spacing-xl);
    padding-bottom: var(--g-spacing-xl);
}

.bottom-wrapper {
    display: flex;
    flex-direction: column;
}

.terms-and-utility {
    @include breakpoint-lg {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: var(--g-spacing-xl);
    }
}

.bottom-nav {
    margin: 0;
}

.disclaimer-text {
    color: var(--s-color-text-inverse);
    margin-bottom: var(--g-spacing-md);

    @include breakpoint-lg {
        margin-bottom: 0;
        flex: 1 0;
    }
}

.bottom-utility-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    gap: var(--g-spacing-md);

    @include breakpoint-lg {
        justify-content: flex-end;
    }
}

.bottom-small-link {
    color: var(--s-color-text-inverse);
    text-decoration: none;

    &:focus-visible {
        text-decoration: underline;
        outline: var(--s-color-text-inverse) solid var(--g-border-width-sm);
        outline-offset: var(--g-spacing-xs);
        border-radius: var(--s-border-radius-interactive-focus);
    }

    &:hover,
    &:active {
        text-decoration: underline;
        transition-duration: var(--g-duration-xshort);
    }
}
