@use "@wojo/ui/mixins" as *;

.link {
    text-decoration: none;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: var(--g-spacing-md);

    @include breakpoint-sm {
        gap: var(--g-spacing-xl);
    }

    &:hover {
        text-decoration: none;
    }

    &:hover .text {
        text-decoration: underline;
        color: var(--s-color-brand-primary-standard);
    }

    &:focus-visible {
        text-decoration: underline;
        border-radius: var(--s-border-radius-interactive-focus);
        outline-offset: var(--g-spacing-sm);
        outline-style: solid;
        outline-width: var(--g-spacing-xxs);
        outline-color: var(--s-color-brand-primary-standard);
        color: var(--s-color-brand-primary-standard);
    }
}

.left {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    justify-content: space-between;
    gap: var(--g-spacing-sm) var(--g-spacing-md);
}

.adornment {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    text-decoration: none;
    gap: var(--g-spacing-sm) 0;
}

.chevron {
    flex-shrink: 0;
}
