.footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--g-spacing-md);
}

.body {
    text-align: center;
}

.heading {
    margin-bottom: var(--g-spacing-sm);
}
