.list {
    display: flex;
    flex-direction: column;
    gap: var(--g-spacing-md);
    padding: var(--g-spacing-md) 0;
    border-bottom: var(--g-border-width-xs) solid
        var(--s-color-separator-standard);
    border-top: var(--g-border-width-xs) solid var(--s-color-separator-standard);

    &--item {
        display: flex;
        justify-content: space-between;
    }
}

.container {
    display: flex;
    flex-direction: column;
    gap: var(--g-spacing-md);
}
