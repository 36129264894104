@use "@wojo/ui/mixins" as *;

.status {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    text-transform: capitalize;
    > * {
        font-size: var(--g-font-size-300);
    }

    @include breakpoint-md {
        justify-content: flex-start;
    }

    &--confirmed {
        color: var(--s-color-success-standard);
        span {
            color: var(--s-color-success-standard);
        }
    }
}

.table {
    padding-bottom: var(--g-spacing-xl);
    border-bottom: var(--g-border-width-xs) solid
        var(--s-color-separator-standard);
    margin-bottom: var(--g-spacing-xl);

    > * {
        font-size: var(--g-font-size-300);
    }
}

.row {
    &--no-border-top {
        border-top: none;
        th,
        td {
            padding-top: var(--g-spacing-md);
        }
    }
    &--no-border-bottom {
        border-bottom: none;
        padding-bottom: 0;
        margin-bottom: 0;
        th,
        td {
            padding-bottom: var(--g-spacing-md);
        }
    }
}

.offset-checkbox {
    th {
        display: flex;
        padding-left: 38px;
        @include breakpoint-md {
            padding-left: 0;
        }
    }
}

.checkbox {
    &--disabled {
        * {
            color: var(--s-color-text-quiet);
        }
    }
}

.footer {
    display: flex;
    gap: var(--g-spacing-lg);
    flex-wrap: wrap;
    align-items: center;
}

.tooltip {
    display: inline-block;
    width: auto;

    + div {
        text-align: left;
    }
}

.alert {
    display: flex;
    justify-content: flex-end;

    > * {
        font-size: var(--g-font-size-300);
    }

    @include breakpoint-md {
        justify-content: flex-start;
    }
}

.header-cell {
    padding-left: 36px;
}
