@use "@wojo/ui/mixins" as *;

.card {
    padding: var(--g-spacing-md);
    @include breakpoint-md {
        padding: var(--g-spacing-xl);
    }
}

.form {
    display: flex;
    flex-direction: column;
    gap: var(--g-spacing-md);
    max-width: 300px;

    button {
        flex: 0 0 auto;
        align-self: start;
    }
}

.requested {
    padding-bottom: var(--g-spacing-md);
}
