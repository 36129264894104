@use "@wojo/ui/mixins" as *;

.containment-context {
    container-type: inline-size;
    container-name: tokenizationframe;
    position: relative;
}

.responsive-frame {
    height: 585px;
}

@container tokenizationframe (min-width: 576px) {
    .responsive-frame {
        height: 340px;
    }
}
