@use "../../styles/mixins" as *;
@include layer(ui) {
    .container {
        display: flex;
        margin: 0;
        min-height: var(--s-size-height-field-standard);
        appearance: none;
        align-items: flex-end;
        box-sizing: border-box;
        border-radius: var(--s-border-radius-field-standard);
        border-width: var(--s-border-width-field-default);
        border-style: solid;
        border-color: var(--s-color-field-border-default);
        resize: vertical;
        ::placeholder {
            color: var(--s-color-text-quiet);
        }
        &--focused {
            outline-width: var(--s-border-width-field-focus);
            outline-color: var(--s-color-field-border-active);
            border-color: var(--s-color-field-border-active);
            color: var(--s-color-text-primary);
            outline-style: solid;
        }
        &--not-disabled {
            &:hover:not(:focus-within) {
                border-width: var(--s-border-width-field-active);
                border-color: var(--s-color-field-border-active);
                color: var(--s-color-text-primary);
                outline-style: none;
            }
        }
        &--error {
            border-color: var(--s-color-field-border-error);
            color: var(--s-color-field-border-error);
        }
    }
    .input {
        background-color: transparent;
        border: 0;
        height: 100%;
        width: 100%;
        outline: none !important;
        font-family: var(--g-font-family-primary);
        font-weight: var(--g-font-weight-regular);
        font-size: var(--g-font-size-400);
        line-height: var(--g-line-height-400);
        color: var(--s-color-text-primary);
        min-height: 150px;
        padding: var(--g-spacing-xs) var(--g-spacing-md);
        resize: vertical;
        box-sizing: border-box;
    }
    .disabled {
        border-color: var(--s-color-disabled-primary);
        color: var(--s-color-disabled-primary);
        cursor: not-allowed;
    }
    .characterCount {
        &--limit-reached {
            color: var(--s-color-alert-standard);
        }
    }
    .adornment {
        display: inline-flex;
        align-items: flex-end;
        box-sizing: border-box;
        margin: 0 8px 8px 0;
    }
}
