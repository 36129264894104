@use "../../styles/mixins" as *;
@include layer(ui) {
    .wrapper {
        display: grid;
        position: relative;
    }

    .container {
        display: inline-flex;
        justify-content: space-between;
        align-items: center;
        min-height: var(--s-size-height-field-standard);
        border: var(--s-border-width-field-default) solid
            var(--s-color-field-border-default);
        border-radius: var(--s-border-radius-field-standard);
        background-color: transparent;
        padding: 0 var(--g-spacing-md) 0 var(--g-spacing-md);
        font-family: var(--g-font-family-primary);
        font-weight: var(--g-font-weight-regular);
        font-size: var(--g-font-size-400);
        line-height: var(--g-line-height-400);
        color: var(--s-color-text-quiet);
        cursor: pointer;
        overflow: hidden;
        max-width: 100%;

        &:hover,
        &[aria-expanded="true"] {
            border: var(--s-border-width-field-active) solid
                var(--s-color-field-border-active);
        }

        &:focus:not([aria-disabled="true"]):not([aria-invalid="true"]) {
            border: var(--s-border-width-field-focus) solid
                var(--s-color-field-border-focus);
            outline: var(--s-border-width-field-focus) solid
                var(--s-color-field-border-focus);
        }

        &--has-selection {
            color: var(--s-color-text-primary);
        }

        &[aria-disabled="true"] {
            border-color: var(--s-color-disabled-primary);
            color: var(--s-color-disabled-primary);
            cursor: not-allowed;
        }

        &[aria-invalid="true"]:not([aria-disabled="true"]) {
            border: var(--s-border-width-field-default) solid
                var(--s-color-field-border-error);

            &:focus {
                outline-color: var(--s-color-field-border-focus);
                border-color: var(--s-color-field-border-focus);
            }
        }
    }

    .value {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
    }

    .icon {
        display: inline-flex;
        align-items: center;
        box-sizing: border-box;
        height: var(--g-size-xxs);
        padding-left: var(--g-spacing-md);
        color: var(--s-color-icon-primary);
        cursor: pointer;

        &--disabled {
            color: var(--s-color-disabled-primary);
            cursor: not-allowed;
        }

        &--error {
            color: var(--s-color-alert-standard);
        }
    }

    .list {
        font-family: var(--g-font-family-primary);
        font-weight: var(--g-font-weight-regular);
        font-size: var(--g-font-size-400);
        line-height: var(--g-line-height-400);
        color: var(--s-color-selection-foreground-default);
        padding: var(--g-spacing-sm);
        border-radius: var(--s-border-radius-field-standard);
        background-color: var(--s-color-selection-background-default);
        box-shadow: var(--s-shadow-standard-default);
        overflow: auto;
        outline: transparent solid 1px;
        outline-offset: -1px;
        z-index: 1;
        width: 100%;
        box-sizing: border-box;

        &::-webkit-scrollbar {
            width: calc(4px + var(--g-spacing-xs) * 2);
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 500rem;
            background-color: var(--s-color-scrollbar-default);
            border: var(--g-spacing-xs) solid
                var(--s-color-selection-background-default);
        }
    }

    .item {
        padding: var(--g-spacing-sm);
        background-color: var(--s-color-selection-background-default);
        border-radius: var(--s-border-radius-field-standard);
        cursor: pointer;
        outline: 0;

        &[aria-selected="true"] {
            background-color: var(--s-color-selection-background-selected-bold);
            color: var(--s-color-selection-foreground-selected-bold);
            outline: transparent solid 1px;
            outline-offset: -1px;
        }

        &:hover:not([aria-selected="true"]),
        &[data-active="true"]:not([aria-selected="true"]) {
            background-color: var(--s-color-selection-background-active);
            color: var(--s-color-selection-foreground-active);
            outline: transparent solid 1px;
            outline-offset: -1px;
        }
    }

    .loading-spinner {
        height: var(--g-size-xxs);
        width: var(--g-size-xxs);
    }
}
