@use "../../styles/mixins" as *;
@include layer(ui) {
    .loading-spinner {
        animation: loading-spinner-animation 1.1s linear infinite;
        transform-origin: center;
        overflow: visible;
        &--standard {
            color: var(--loader-color-default, currentColor);

            @media (forced-colors: active) {
                color: currentColor;
            }
        }

        &--inverse {
            color: var(--s-color-icon-inverse, currentColor);

            @media (forced-colors: active) {
                color: currentColor;
            }
        }

        &-container {
            opacity: 0.3;
            fill: currentColor;
        }

        &-indicator {
            fill: currentColor;
        }
    }

    @keyframes loading-spinner-animation {
        0% {
            transform: rotate(0);
        }
        100% {
            transform: rotate(360deg);
        }
    }
}
