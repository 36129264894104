@use "@wojo/ui/mixins" as *;

.card {
    padding: var(--g-spacing-md);
    position: relative;
    margin-bottom: var(--g-spacing-lg);

    @include breakpoint-md {
        padding: var(--g-spacing-xl);
    }
}

.header {
    display: flex;
    flex-direction: column;
    margin-bottom: var(--g-spacing-md);
    gap: var(--g-spacing-sm);

    @include breakpoint-md {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 0;
    }
}

.content {
    display: grid;
    grid-template-columns: 1fr;
    gap: var(--g-spacing-md);

    @include breakpoint-md {
        grid-template-columns: 1fr 1fr;
        gap: 0;
    }
}

.segment {
    &-heading {
        margin-bottom: var(--g-spacing-sm);
    }
    &-content {
        display: flex;
        flex-direction: column;
        gap: var(--g-spacing-xs);

        span {
            display: flex;
            gap: var(--g-spacing-xs);
            align-items: center;
        }
    }
}
