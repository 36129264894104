.title {
    margin-bottom: var(--g-spacing-md);
}

.trip-list {
    display: flex;
    flex-direction: column;
    gap: var(--g-spacing-xl);
    padding-bottom: var(--g-spacing-xl);
}
