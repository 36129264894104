.frame {
    height: 585px;
    position: relative;
}

.loading {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
}
