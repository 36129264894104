@import "../../base-table/BaseTable.module.scss";

.address,
.contact {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    font-style: normal;

    a {
        color: var(--s-color-text-primary);
        font-weight: var(--g-font-weight-regular);
    }
}

.contact {
    word-wrap: anywhere;
}
