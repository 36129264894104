@use "../../../styles/mixins" as *;
@include layer(ui) {
    .icon {
        stroke-width: var(--c-alert-banner-icon-stroke-width);
        width: var(--g-size-xs);
        height: var(--g-size-xs);
    }

    .icon-wrapper {
        align-items: center;
        display: flex;
        height: 1lh;
    }
}
