@use "@wojo/ui/mixins" as *;

.info-table {
    font-family: var(--g-font-family-primary);
    padding: var(--g-spacing-md);

    @include breakpoint-md {
        padding: var(--g-spacing-lg) var(--g-spacing-xl);
    }

    .row {
        border-bottom: var(--g-border-width-xs) solid
            var(--s-color-separator-standard);
        display: grid;
        grid-template-columns: 1fr auto;
        padding: var(--g-spacing-md) 0;

        &--no-separator {
            border: 0;
        }
    }
}

.row-content {
    display: grid;
    justify-items: end;
    text-align: right;
    overflow-wrap: anywhere;
}

.payment-overview-alert {
    display: flex;
    justify-content: flex-end;
}
