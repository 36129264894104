.wrapper {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
}

.overlay-spinner {
    width: 100%;
    height: 100%;
    background-color: var(--s-color-surface-secondary);
    animation: fade-in var(--g-duration-xshort) forwards;
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: var(--g-opacity-moderate);
    }
}
