.modal-header {
    display: flex;
    flex-direction: column;
    gap: var(--g-spacing-sm);
    align-items: flex-start;
}

.form {
    display: flex;
    flex-direction: column;
    gap: var(--g-spacing-md);

    &-lower-half {
        display: flex;
        flex-direction: column;
        gap: var(--g-spacing-xl);
    }
}

.mailing-address {
    margin-top: var(--g-spacing-md);
    display: flex;
    flex-direction: column;
    gap: var(--g-spacing-md);
}

.modal-footer {
    display: flex;
    justify-content: flex-end;
}
