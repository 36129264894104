.section-wrapper {
    border-top: var(--g-border-width-xs) solid var(--s-color-separator-standard);
}

.option-heading-wrapper {
    display: flex;
    justify-content: space-between;
    margin-top: var(--g-spacing-md);
}

.title,
.more-info {
    margin-bottom: 0;
    text-align: center;
    margin-bottom: var(--g-spacing-md);

    &--subtitle {
        margin-bottom: var(--g-spacing-xs);
    }
}

.subtitle {
    margin-bottom: var(--g-spacing-md);
    text-align: left;
}
