.image {
    display: flex;
    justify-content: center;
    align-items: center;

    max-width: 100px;
    max-height: 100px;
    margin-bottom: var(--g-spacing-lg);

    // Fallback: For those browsers that don't support style queries yet
    // we can name the contain based on the theme and then query by container name
    // See native style query rules below
    @container light (min-width: 0) {
        &--dark {
            display: none;
        }
    }
    @container dark (min-width: 0) {
        &--light {
            display: none;
        }
    }

    // Native browser style queries
    @container style(--g-ui-theme: light) {
        &--dark {
            display: none;
        }
    }
    @container style(--g-ui-theme: dark) {
        &--light {
            display: none;
        }
    }
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
}
