@use "../../styles/mixins" as *;
@include layer(ui) {
    .wrapper {
        position: relative;
        display: inline-block;
        font-family: var(--g-font-family-primary);
    }

    .children {
        position: absolute;
    }

    .children-none {
        position: static;
    }

    .number {
        background-color: var(--s-color-alert-standard);
        color: var(--s-color-text-inverse);
        box-sizing: border-box;
        font-size: var(--g-font-size-200);
        line-height: 100%;
        font-weight: var(--g-font-weight-bold);
        min-height: var(--c-badge-size);
        min-width: var(--c-badge-size);
        border-radius: 10rem;
        padding: var(--g-spacing-xxs) 6px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        z-index: 1;
    }

    .dot {
        background-color: var(--s-color-alert-standard);
        box-sizing: border-box;
        display: inline-flex;
        z-index: 1;
        border: var(--g-border-width-sm) solid var(--s-color-surface-primary);
        height: 12px;
        width: 12px;
        border-radius: 50%;
    }

    .position {
        &-top-right {
            top: var(--badge-offset);
            right: var(--badge-offset);
        }

        &-top-left {
            top: var(--badge-offset);
            left: var(--badge-offset);
        }

        &-bottom-left {
            bottom: var(--badge-offset);
            left: var(--badge-offset);
        }

        &-bottom-right {
            bottom: var(--badge-offset);
            right: var(--badge-offset);
        }
    }
}
