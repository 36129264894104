.image-wrapper {
    // See Fallback note below
    container: var(--g-ui-theme) / inline-size;
    display: inline-flex;
    height: var(--size);
    width: var(--size);
    vertical-align: middle;
}

.image {
    display: inline-flex;

    // Fallback: For those browsers that don't support style queries yet
    // we can name the contain based on the theme and then query by container name
    // See native style query rules below
    @container light (min-width: 0) {
        &--dark {
            display: none;
        }
    }
    @container dark (min-width: 0) {
        &--light {
            display: none;
        }
    }

    // Native browser style queries
    @container style(--g-ui-theme: light) {
        &--dark {
            display: none;
        }
    }
    @container style(--g-ui-theme: dark) {
        &--light {
            display: none;
        }
    }
}
