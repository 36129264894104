@use "@wojo/ui/mixins" as *;

.account-nav-container {
    margin-bottom: var(--g-spacing-lg);

    .account-nav-menu-button {
        width: 100%;
        display: flex;
        color: var(--brand-text-color-primary);
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font-family: var(--g-font-family-primary);
        font-size: var(--g-font-size-400);
        font-weight: var(--g-font-weight-bold);
        line-height: var(--g-line-height-400);
        padding: var(--g-spacing-sm) var(--g-spacing-xl) var(--g-spacing-sm)
            var(--g-spacing-md);
        box-sizing: border-box;
        gap: var(--g-spacing-sm);
        min-height: 48px;
        border-radius: var(--s-border-radius-background-small);

        @include breakpoint-lg {
            display: none;
        }
    }

    .account-nav {
        display: none;
        flex-shrink: 0;
        flex-grow: 0;

        @include breakpoint-lg {
            display: flex;
            width: 100%;
        }

        @include breakpoint-xl {
            width: 100%;
        }

        flex-direction: column;
        align-items: flex-start;
        box-sizing: border-box;
        padding: 0;
    }

    .account-header {
        display: flex;
        padding: var(--g-spacing-md) var(--g-spacing-sm) var(--g-spacing-md)
            var(--g-spacing-sm);
        flex-direction: column;
        align-items: center;
        gap: var(--g-spacing-sm);
        align-self: stretch;
        border-bottom: var(--g-border-width-xs) solid
            var(--s-color-separator-standard);
        text-decoration: none;
    }

    .account-header-link {
        text-decoration: none;
        flex-direction: column;
        display: flex;
        align-items: center;
        gap: var(--g-spacing-sm);

        &:focus-visible {
            border-radius: var(--s-border-radius-interactive-focus);
            outline: var(--g-border-width-sm) solid
                var(--s-color-brand-primary-standard);
            outline-offset: var(--g-spacing-sm);
        }
    }

    .account-links {
        display: flex;
        padding: var(--g-spacing-sm);
        flex-direction: column;
        align-items: flex-start;
        gap: var(--g-spacing-sm);
        align-self: stretch;
        box-sizing: border-box;
    }

    .account-links-list {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        gap: var(--g-spacing-sm);
        width: 100%;
        margin: 0;
        padding: 0;
        list-style-type: none;
        line-height: inherit;

        & > * {
            width: 100%;
        }
    }
}

.zendesk-link {
    text-decoration: none;
    padding: var(--g-spacing-sm);
    svg {
        margin-left: var(--g-spacing-xs);
    }
}
