@use "@wojo/ui/mixins" as *;

.card {
    position: relative;
    margin-bottom: var(--g-spacing-xl);
}

.view-link {
    position: absolute;
    right: var(--c-card-padding);
    top: var(--c-card-padding);

    @include breakpoint-md {
        right: var(--c-card-padding-desktop);
        top: var(--c-card-padding-desktop);
    }
}

.separator {
    border-bottom: var(--g-border-width-xs) solid
        var(--s-color-separator-standard);
    margin-bottom: var(--g-spacing-md);
    padding-bottom: var(--g-spacing-md);
}
