@use "@wojo/ui/mixins" as *;
.card {
    margin-top: var(--g-spacing-lg);
    margin-left: calc(var(--g-spacing-xl) * -1);
    margin-bottom: var(--g-spacing-md);
    display: flex;
    flex-direction: column;
    gap: var(--g-spacing-md);
    @include breakpoint-sm {
        margin-left: 0;
    }
}
