.component-wrapper {
    border-bottom: var(--g-border-width-xs) solid
        var(--s-color-separator-standard);
    display: flex;
    justify-content: space-between;
    margin-bottom: var(--g-spacing-md);
    padding-bottom: var(--g-spacing-md);
}

.carrier-list {
    display: flex;
    flex-direction: column;
    gap: var(--g-spacing-md);
    list-style: none;
    margin: 0;
    padding: 0;
}

.carrier-list-item {
    margin: 0;
    padding: 0;
    text-align: right;
}

.number-list {
    list-style: none;
    margin: 0;
    padding: 0;
}

.number-list-item {
    margin: 0;
    padding: 0;
}
