@use "@wojo/ui/mixins" as *;

.tour-overview-wrapper {
    margin-bottom: var(--g-spacing-xxl);
    padding: var(--g-spacing-md) var(--g-spacing-md) var(--g-spacing-lg)
        var(--g-spacing-md);

    @include breakpoint-sm {
        padding: var(--g-spacing-lg) var(--g-spacing-xl);
    }
}
.tour-overview-list {
    padding: 0;
    margin: 0;
    list-style: none;

    > :first-child {
        padding-top: 0;
    }

    > :last-child {
        border-bottom: none;
        padding-bottom: 0;
    }

    li {
        padding: var(--g-spacing-md) 0;
        border-bottom: var(--g-border-width-xs) solid
            var(--s-color-separator-standard);

        &:hover {
            svg {
                color: var(--s-color-brand-primary-standard);
            }
        }
    }

    a {
        font-weight: var(--g-font-weight-bold);
        justify-content: space-between;
    }
}
