@use "@wojo/ui/mixins" as *;

.preface {
    margin-bottom: var(--g-spacing-md);

    @include breakpoint-md {
        margin-bottom: var(--g-spacing-lg);
    }

    p + p {
        margin-top: var(--g-spacing-sm);
    }
}

.flight-cards {
    display: flex;
    flex-direction: column;
    gap: var(--g-spacing-md);
    margin-bottom: var(--g-spacing-xl);

    @include breakpoint-md {
        gap: var(--g-spacing-lg);
    }
}

.flight-card {
    width: 100%;
}

.change-flights-text {
    margin-bottom: var(--g-spacing-xl);

    @include breakpoint-md {
        margin-bottom: var(--g-spacing-lg);
    }
}
