@use "@wojo/ui/mixins" as *;

.top-list {
    @include for-tablet-up {
        display: none;
    }
}

.collapsible-title {
    color: var(--s-color-text-inverse);
}

.collapsible-list-item {
    list-style-type: none;
    text-decoration: none;

    svg {
        color: var(--s-color-icon-inverse);
    }

    button {
        &:focus-visible {
            outline-color: var(--s-color-text-inverse);
        }
    }
}

.collapsible-link-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.list-item {
    color: var(--s-color-text-inverse);
    margin-bottom: var(--g-spacing-md);

    &:first-child {
        margin-top: var(--g-spacing-md);
    }

    &:last-child {
        margin-bottom: 0;
    }
}

.address {
    margin-top: var(--g-spacing-xl);
    font-style: normal;
}

.link {
    color: var(--s-color-text-inverse);
    text-decoration: none;

    &:focus-visible {
        text-decoration: underline;
        outline: var(--s-color-text-inverse) solid var(--g-border-width-sm);
        outline-offset: var(--g-spacing-xs);
        border-radius: var(--s-border-radius-interactive-focus);
    }

    &:hover,
    &:active {
        text-decoration: underline;
        transition-duration: var(--g-duration-xshort);
    }
}

.border {
    margin: 0;
    border-top: var(--g-border-width-xs) solid var(--s-color-separator-standard);
    opacity: 0.3;
}
