.traveler-list {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
        margin: 0;
        padding: 0;
    }
}

.segments-wrapper {
    border-bottom: var(--g-border-width-xs) solid
        var(--s-color-separator-standard);
    border-top: var(--g-border-width-xs) solid var(--s-color-separator-standard);
    margin-bottom: var(--g-spacing-lg);
    margin-top: var(--g-spacing-md);
    padding-bottom: var(--g-spacing-lg);
    padding-top: var(--g-spacing-md);
}

.overview-wrapper {
    display: flex;
    justify-content: space-between;
}

.gateway-code {
    margin-bottom: var(--g-spacing-xs);
}

.departure-gateway-detail {
    white-space: nowrap;
    width: 1px;
}

.arrival-gateway-detail > span {
    display: inline-flex;
    justify-content: flex-end;
    white-space: nowrap;
    width: 1px;
}

.arrival-gateway-wrapper {
    text-align: right;
}

.intermediate-stops-wrapper {
    flex-grow: 1;
}

.decorative-line-wrapper {
    position: relative;
    flex-grow: 1;
}

.decorative-line {
    position: absolute;
    left: 12px;
    top: 50%;
    right: 12px;
    border-bottom: var(--g-border-width-xs) solid var(--g-color-ink-black);
}

.stops-icon {
    background: var(--s-color-surface-secondary);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 0 var(--g-spacing-sm);
    text-align: center;
}

.stops-icon-text {
    padding-top: var(--g-spacing-xs);
}
