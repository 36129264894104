@use "@wojo/ui/mixins" as *;
.wrapper {
    color: var(--s-color-text-primary);
    font-family: var(--g-font-family-primary);
    font-size: var(--g-font-size-400);
    font-weight: var(--g-font-weight-regular);
    line-height: var(--g-line-height-400);
    outline: var(--g-border-width-xs) solid transparent;
    overflow: hidden;
    padding: 0;
    display: grid;
    grid-template-columns: minmax(109px, 10%) 1fr;
    position: relative;

    @include breakpoint-md {
        grid-template-columns: 120px 1fr;
    }
}

.card-details {
    order: 1;
    padding: var(--g-spacing-md);
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    @include breakpoint-xl {
        flex-direction: row;
        gap: var(--g-spacing-md);
        align-items: flex-start;
        justify-content: space-between;
    }
}

.card-details-inner {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    max-width: 100%;
}

.quote-name {
    margin-top: var(--g-spacing-sm);

    @include breakpoint-md {
        width: 100%;
    }

    @include breakpoint-xl {
        grid-area: name;
        max-width: 188px;
        margin-top: 0;
    }
}

.quote-details {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    @include breakpoint-md {
        flex-direction: row;
        align-items: flex-end;
    }

    @include breakpoint-xl {
        display: grid;
        grid-template-columns: minmax(25%, auto) minmax(25%, auto) auto;
        grid-template-areas: "name dates trip-type";
        gap: var(--g-spacing-md);
        width: 100%;
        align-items: center;
    }
}

.trip-price {
    align-items: flex-start;
    display: flex;
    align-content: flex-start;
    margin-top: var(--g-spacing-sm);
    @include breakpoint-lg {
        margin-top: 0;
        margin-right: var(--g-spacing-md);
    }
}

.price {
    color: var(--s-color-promo-deal-standard);
    margin-right: 0;

    &--expired {
        color: var(--s-color-text-primary);
        text-decoration: line-through;
        font-weight: var(--g-font-weight-regular);
    }

    @include breakpoint-xl {
        align-self: flex-start;
        margin-right: var(--g-spacing-md);
        margin-left: var(--g-spacing-xl);
    }
}

.price-type-wrapper {
    display: flex;
    justify-content: flex-start;
    margin-top: var(--g-spacing-sm);

    @include breakpoint-xl {
        grid-area: trip-type;
        margin-top: 0;
        justify-content: flex-start;
        display: grid;
        grid-template-columns: minmax(80px, auto) minmax(100px, auto);
        gap: var(--g-spacing-xs);
    }
}

.quote-actions {
    margin-top: var(--g-spacing-md);
    padding-top: var(--g-spacing-md);
    border-top: var(--g-border-width-xs) solid var(--s-color-separator-standard);
    a,
    button {
        margin-right: var(--g-spacing-md);
        margin-bottom: var(--g-spacing-sm);

        @include breakpoint-sm {
            margin-bottom: 0;
        }
    }

    @include breakpoint-xl {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-direction: column;
        border-top: none;
        margin-top: 0;
        padding-top: 0;
        flex-shrink: 0;
        margin-bottom: 0;
        align-self: center;

        a,
        button {
            margin-right: 0;
            white-space: nowrap;
            margin-bottom: var(--g-spacing-sm);
        }

        a:last-child,
        button:last-child {
            margin-bottom: 0;
        }
    }
}

.trip-type {
    display: flex;
    align-items: flex-start;
    margin-right: var(--g-spacing-sm);

    svg,
    img {
        margin-right: var(--g-spacing-xs);
    }

    @include breakpoint-md {
        margin-right: var(--g-spacing-md);
    }

    @include breakpoint-xl {
        margin-right: 0;
    }

    p {
        display: flex;
        align-items: center;
    }
}

.tour-image {
    order: 0;
    overflow: hidden;

    @include breakpoint-xl {
        width: 120px;
        height: 100%;
        display: flex;
        align-items: stretch;
    }

    picture {
        display: flex;
        height: 100%;
    }

    img {
        height: 100%;
        max-height: 100%;
        object-fit: cover;
        object-position: center;
        width: 100%;
    }
}

.expiration-label {
    margin-bottom: var(--g-spacing-sm);
    display: inline-flex;
    max-width: 300px;
}

.trip-dates {
    margin-top: var(--g-spacing-sm);
    font-weight: var(--g-font-weight-light);
    @include breakpoint-md {
        grid-area: dates;
        margin-top: 0;
        margin-right: var(--g-spacing-md);
    }

    @include breakpoint-xl {
        margin-right: 0;
    }
}

.modal-title {
    text-align: center;
}

.modal-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: var(--g-spacing-md);

    button,
    a {
        width: auto;
        margin-bottom: var(--g-spacing-md);
    }
}
