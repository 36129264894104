@use "@wojo/ui/mixins" as *;

.card {
    position: relative;
    margin-bottom: var(--g-spacing-xl);
}

.grid {
    &:not(.grid--with-see-details):not(.grid--with-badge) {
        display: block;
    }

    &--with-badge,
    &--with-see-details {
        align-items: center;
        display: grid;
        gap: var(--g-spacing-md);
        grid-template-rows: auto;
        grid-template-columns: 1fr max-content;

        .badge-wrapper,
        .mobile-right-wrapper,
        .content-wrapper,
        .see-details-wrapper {
            @include breakpoint-md {
                grid-row: 1;
            }
        }

        .content-wrapper {
            grid-column: 1;
        }

        .mobile-right-wrapper {
            grid-column: 2;

            @include breakpoint-md {
                display: contents;
            }
        }
    }

    &--with-badge.grid--with-see-details {
        @include breakpoint-md {
            grid-template-columns: max-content 1fr max-content;
        }

        .badge-wrapper {
            @include breakpoint-md {
                grid-column: 1;
            }
        }

        .content-wrapper {
            @include breakpoint-md {
                grid-column: 2;
            }
        }

        .see-details-wrapper {
            @include breakpoint-md {
                grid-column: 3;
            }
        }
    }

    &--with-badge:not(.grid--with-see-details) {
        @include breakpoint-md {
            grid-template-columns: max-content 1fr;
        }

        .badge-wrapper {
            @include breakpoint-md {
                grid-column: 1;
            }
        }

        .content-wrapper {
            @include breakpoint-md {
                grid-column: 2;
            }
        }
    }

    &--with-see-details:not(.grid--with-badge) {
        @include breakpoint-md {
            grid-template-columns: 1fr max-content;
        }

        .content-wrapper {
            @include breakpoint-md {
                grid-column: 1;
            }
        }

        .see-details-wrapper {
            @include breakpoint-md {
                grid-column: 2;
            }
        }
    }
}

.badge-wrapper {
    height: var(--g-size-xxxl);
    width: var(--g-size-xxxl);

    picture {
        display: flex;
        justify-content: center;
    }

    img {
        height: var(--g-size-xxxl);
        width: var(--g-size-xxxl);
    }
}

.mobile-right-wrapper {
    display: flex;
    flex-direction: column;
    gap: var(--g-spacing-md);

    @include breakpoint-md {
        display: contents;
    }
}

.separator {
    border-bottom: var(--g-border-width-xs) solid
        var(--s-color-separator-standard);
    margin-bottom: var(--g-spacing-md);
    padding-bottom: var(--g-spacing-md);
}
