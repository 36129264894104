a.nav-item-title {
    padding-top: var(--g-spacing-sm);
    padding-bottom: var(--g-spacing-sm);
    padding-right: var(--g-spacing-sm);

    &:focus-visible {
        border-radius: var(--c-menu-item-border-radius-focus);
        outline-offset: 0;
        outline-style: solid;
        color: var(--s-color-brand-primary-standard);
    }

    &--main {
        padding-left: var(--g-spacing-sm);
    }

    &--sub-section {
        padding-left: var(--g-spacing-md);
    }

    &--page {
        padding-left: var(--g-spacing-md);
    }

    &--sub-page {
        padding-left: var(--g-spacing-md);
        padding-right: var(--g-spacing-md);
    }

    &--always-shown-sub-item {
        padding-right: var(--g-spacing-md);
    }

    span.badge-visible {
        display: flex;
    }

    span.badge-hidden {
        display: none;
    }

    .nav-item-text--selected {
        font-weight: var(--g-font-weight-bold);
    }
}

button:focus-visible svg.chevron {
    color: var(--s-color-brand-primary-standard);
}

button:hover svg.chevron,
button:active svg.chevron {
    color: var(--s-color-brand-primary-standard);
}

.nav-item-button {
    button:focus-visible {
        outline-offset: 0;
    }

    &--sub-section {
        margin-top: var(--g-spacing-sm);
    }

    &--selected {
        border-radius: var(--c-menu-item-border-radius-focus);
        background: var(--s-color-surface-quiet);
    }
}

.sub-items {
    list-style-type: none;

    &--main {
        padding-left: var(--g-spacing-lg);
    }

    &--sub-section {
        padding-left: var(--g-spacing-lg);
    }

    &--page {
        padding-left: var(--g-spacing-md);
    }

    &--always-shown-sub-item {
        padding-right: var(--g-spacing-md);
    }
}

.sub-page-flex {
    display: flex;
    align-items: flex-start;
    gap: 0;

    .nav-item-button {
        flex-grow: 1;
    }
}
