@use "@wojo/ui/mixins" as *;
@include layer(storyblok) {
    .images {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        margin-bottom: var(--g-spacing-md);
        gap: var(--g-spacing-md);
    }
    .image-container {
        position: relative;
        display: inline-block;
        margin-right: var(--g-spacing-md);
        margin-top: var(--g-spacing-md);
    }

    .delete-button {
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(50%, -50%);
    }
}
