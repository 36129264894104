.review-form {
    border-bottom: var(--g-border-width-xs) solid
        var(--s-color-separator-standard);
    margin-bottom: var(--g-spacing-lg);
}

.radio-buttons {
    padding-bottom: var(--g-spacing-sm);
}

.fieldset-wrapper {
    border: 0;
    margin: 0;
    padding: 0;
}
