.address-fields {
    display: flex;
    flex-direction: column;
    gap: var(--g-spacing-md);
}

.additional-field {
    margin-top: var(--g-spacing-md);
}

.payment-terms {
    margin-bottom: var(--g-spacing-md);
    overflow: hidden;
    border: var(--g-border-width-xs) solid var(--s-color-field-border-default);
    border-radius: var(--s-border-radius-background-standard);
    padding: var(--g-spacing-md);
    &:focus-visible {
        outline: var(--g-border-width-sm) solid
            var(--s-color-brand-primary-standard);
    }

    &--inner {
        height: 200px;
        overflow-x: hidden;
        overflow-y: scroll;
    }
}
