@use "@wojo/ui/mixins" as *;

.wrapper {
    background: var(--s-color-info-background);
    border: var(--s-border-width-background-standard) solid;
    border-color: var(--s-color-info-border);
    border-radius: var(--s-border-radius-background-standard);
    color: var(--c-alert-banner-info-text-color);
    font-family: var(--g-font-family-primary);
    padding: var(--g-spacing-xl);
    text-align: center;
    // See Fallback note below
    container: var(--g-ui-theme) / inline-size;
    .image {
        display: flex;
        justify-content: center;

        // Fallback: For those browsers that don't support style queries yet
        // we can name the contain based on the theme and then query by container name
        // See native style query rules below
        @container light (min-width: 0) {
            &--dark {
                display: none;
            }
        }
        @container dark (min-width: 0) {
            &--light {
                display: none;
            }
        }

        // Native browser style queries
        @container style(--g-ui-theme: light) {
            &--dark {
                display: none;
            }
        }
        @container style(--g-ui-theme: dark) {
            &--light {
                display: none;
            }
        }
    }
}
