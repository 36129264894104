@use "@wojo/ui/mixins" as *;

.link {
    &:focus-visible {
        color: var(--s-color-brand-primary-standard);
        outline-color: currentColor;
        outline-width: var(--g-border-width-sm);
        outline-offset: var(--g-spacing-xxs);
        outline-style: solid;
        border-radius: var(--s-border-radius-interactive-focus);
    }
}

.logo {
    img {
        height: 32px;

        @include breakpoint-md {
            height: 40px;
        }
    }
}
