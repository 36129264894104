.segment-title {
    display: flex;
    justify-content: space-between;
    margin-bottom: var(--g-spacing-sm);
}

.carrier-image-wrapper {
    margin-bottom: var(--g-spacing-md);
}

.decorative-line-wrapper {
    position: relative;
}

.decorative-line {
    position: absolute;
    left: calc(-1 * var(--g-spacing-md));
    top: calc(1lh / 2);
    bottom: calc(1lh / 2);
    border-right: 1px solid var(--s-color-separator-standard);

    &::before,
    &::after {
        content: "";
        position: absolute;
        left: 0.5px;
        width: 3px;
        height: 3px;
        background: var(--g-color-ink-black);
        border: var(--g-spacing-xs) solid var(--s-color-surface-secondary);
        border-radius: 50%;
    }

    &::before {
        top: 0;
        transform: translate(-50%, -50%);
    }

    &::after {
        bottom: 0;
        transform: translate(-50%, 50%);
    }
}

.layover-wrapper {
    border: 0 solid var(--s-color-separator-standard);
    border-width: var(--g-border-width-xs) 0;
    display: flex;
    margin: var(--g-spacing-md) 0;
    padding: var(--g-spacing-md) 0;
}
