@use "@wojo/ui/mixins" as *;

.qr-and-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--g-spacing-md);
    align-self: stretch;
    .text {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    padding: 0 var(--g-spacing-lg) var(--g-spacing-lg) var(--g-spacing-lg);
}

.line {
    border-top: 1px solid var(--s-color-separator-standard);
    display: block;
    width: 100%;
}

.buttons {
    display: flex;
    flex-direction: column;
    gap: var(--g-spacing-md);
    padding: var(--g-spacing-md) 0;
    align-items: center;
}

.outer-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}
