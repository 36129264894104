@use "@wojo/ui/mixins" as *;

.container {
    display: flex;
    flex-direction: column;
    gap: var(--g-spacing-xl);
    padding-bottom: var(--g-spacing-xl);
    max-width: 100%;

    @include breakpoint-md {
        flex-direction: row;
    }

    @include breakpoint-xl {
        padding: 0;
    }
}

.text {
    color: var(--s-color-text-inverse);

    @include breakpoint-md {
        padding-bottom: 0;
    }
}

.list {
    display: grid;
    grid-template: 1fr 1fr / 1fr 1fr;
    grid-auto-flow: column;
    padding: 0;
    margin: 0;
    row-gap: var(--g-spacing-lg);
    column-gap: var(--g-spacing-lg);
    list-style: none;
    :last-child {
        grid-row-start: 2;
    }

    @include breakpoint-md {
        display: flex;
        align-items: center;
        gap: var(--g-spacing-lg);
    }
}

.link {
    width: fit-content;

    &:focus-visible {
        outline: var(--s-color-icon-inverse) solid var(--g-border-width-sm);
        outline-offset: var(--g-spacing-xs);
        border-radius: var(--s-border-radius-interactive-focus);
    }
}

.asset {
    height: 20px;
    max-width: 100%;
}
