@use "@wojo/ui/mixins" as *;

.top-list {
    display: none;

    @include for-tablet-up {
        display: grid;
        grid-auto-flow: column;
        padding-bottom: var(--g-spacing-xl);
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: var(--g-spacing-lg);
    }
}

.column-header {
    color: var(--s-color-text-inverse);
    margin-bottom: var(--g-spacing-md);
}

.list {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.list-item {
    padding-bottom: var(--g-spacing-sm);

    &:last-child {
        padding-bottom: 0;
    }
}

.list-item-text {
    color: var(--s-color-text-inverse);
    text-decoration: none;

    &:focus-visible {
        text-decoration: underline;
        outline: var(--s-color-text-inverse) solid var(--g-border-width-sm);
        outline-offset: var(--g-spacing-xs);
        border-radius: var(--s-border-radius-interactive-focus);
    }

    &:hover,
    &:active {
        text-decoration: underline;
        transition-duration: var(--g-duration-xshort);
    }
}

.address {
    margin-top: var(--g-spacing-xl);
    font-style: normal;

    &:active,
    &:focus,
    &:target,
    &:hover,
    &:focus-visible {
        text-decoration: none;
    }
}
