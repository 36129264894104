@use "@wojo/ui/mixins" as *;

.content {
    display: flex;
    flex-direction: column;
    gap: var(--g-spacing-md);

    @include breakpoint-md {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 0;
    }
}

.column-title {
    @include breakpoint-md {
        margin-bottom: var(--g-spacing-sm);
    }
}
