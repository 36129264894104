.list {
    list-style: none;
    padding: 0;

    &--item {
        display: grid;
        grid-template-columns: 1fr auto;
        padding: var(--g-spacing-md) 0;
        border-bottom: var(--g-border-width-xs) solid
            var(--s-color-separator-standard);

        &:first-child {
            border-top: 1px solid #e5e5e5;
        }
    }
}
