.autopay-schedule-info {
    display: flex;
    gap: var(--g-spacing-md);
    margin: 0;
    flex-direction: column;
    padding: 0 var(--g-spacing-md) 0 0;

    &--item {
        display: flex;
        justify-content: space-between;
    }
    &--separator {
        padding: var(--g-spacing-md) 0;
        border-top: var(--g-border-width-xs) solid
            var(--s-color-separator-standard);
        border-bottom: var(--g-border-width-xs) solid
            var(--s-color-separator-standard);
    }
}

.autopay-schedule-input-wrapper {
    display: flex;
    flex-direction: column;
    gap: var(--g-spacing-md);
}

.loading-fallback {
    display: flex;
    flex-direction: column;
    gap: var(--g-spacing-md);
}
