.list {
    margin-bottom: var(--g-spacing-lg);
    font-family: var(--g-font-family-primary);

    .matrix-row-answer {
        margin-bottom: var(--g-spacing-sm);
    }

    .matrix-row-comments {
        margin-bottom: var(--g-spacing-md);
    }

    .matrix-text {
        margin-bottom: var(--g-spacing-sm);
    }

    dl {
        margin: 0;
        padding: 0;
    }

    div {
        margin: 0 0 var(--g-spacing-sm) 0;
    }

    dt {
        display: inline;
    }

    dd {
        display: inline;
        margin: 0 0 0 var(--g-spacing-xs);
    }
}

.submitted {
    margin-bottom: var(--g-spacing-md);
}
