.input-wrapper {
    position: relative;
}

.dropdown-anchor-point {
    position: absolute;
    top: calc(100% + var(--g-spacing-xxs));
    left: 0;
    width: 100%;
}

.logo-container {
    display: flex;
    justify-content: flex-end;
    padding: var(--g-spacing-md);
}

.logo {
    max-width: 100%;
    max-height: 100%;
    height: var(--g-size-xs);
    margin-left: var(--g-spacing-sm);
    margin-right: var(--g-spacing-sm);
    margin-top: var(--g-spacing-md);
}
