.wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0;
    margin-bottom: var(--g-size-xl);
}

.title {
    text-decoration: none;
}
