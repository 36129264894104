@use "../../styles/mixins" as *;
@include layer(ui) {
    .table-mobile-wrapper {
        display: block;
        @include for-tablet-up {
            display: none;
        }
    }

    .table {
        border-collapse: collapse;
        text-align: left;
        table-layout: fixed;
        width: 100%;
        &--desktop {
            display: none;
            @include for-tablet-up {
                display: table;
            }
        }
        &--mobile {
            display: table;
            &:not(:last-of-type) {
                border-bottom: var(--g-border-width-xs) solid
                    var(--s-color-separator-standard);
                margin-bottom: var(--g-spacing-xl);
            }
            @include for-tablet-up {
                display: none;
            }
        }
        &--strict {
            svg {
                width: var(--g-size-xxs);
                height: auto;
            }
            .cell > * {
                font-size: inherit;
                line-height: inherit;
                text-align: inherit;
            }
        }
    }

    .caption {
        text-align: left;
        .cell {
            padding-left: 0;
        }
    }

    .body {
        @include for-tablet-up {
            border-top: 0;
            padding-top: var(--g-spacing-xl);
            padding-bottom: var(--g-spacing-xl);
        }
    }

    .row {
        @include for-tablet-up {
            &:not(:first-of-type) {
                border-top: var(--g-border-width-xs) solid
                    var(--s-color-separator-standard);
            }
            &--header {
                color: var(--s-color-text-primary);
            }
        }
    }

    .cell {
        padding: calc(var(--g-spacing-sm) / 2) calc(var(--g-spacing-md) / 2);
        vertical-align: top;
        &:last-child {
            padding-right: 0;
            text-align: right;
        }
        &:first-child {
            text-align: left;
            padding-left: 0;
        }
        tbody:not(.body--last) .row--last & {
            padding-bottom: var(--g-spacing-xl);
        }
        @include for-tablet-up {
            padding: var(--g-spacing-xl) calc(var(--g-spacing-xl) / 2);
            &:last-child {
                text-align: left;
            }
            .row--header & {
                padding-bottom: 0;
                padding-top: 0;
            }
            .row:last-of-type & {
                padding-bottom: 0;
            }
        }
    }
}
