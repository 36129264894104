@use "@wojo/ui/mixins" as *;

.badge {
    align-items: center;
    border: 1px solid transparent;
    border-radius: 50%;
    display: flex;
    height: var(--g-size-xxxl);
    justify-content: center;
    width: var(--g-size-xxxl);

    span {
        color: var(--s-color-text-inverse);
    }

    &--level1 {
        background: var(--s-gradient-brand-global-rewards-level1);
    }

    &--level2 {
        background: var(--s-gradient-brand-global-rewards-level2);
    }

    &--level3 {
        background: var(--s-gradient-brand-global-rewards-level3);
    }

    &--level4 {
        background: var(--s-gradient-brand-global-rewards-level4);
    }

    &--level5 {
        background: var(--s-gradient-brand-global-rewards-level5);
    }
}

.content {
    display: flex;
    flex-direction: column;
    gap: var(--g-spacing-md);

    @include breakpoint-md {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 0;
    }
}

.column-title {
    @include breakpoint-md {
        margin-bottom: var(--g-spacing-sm);
    }
}
