@use "@wojo/ui/mixins" as *;

.card {
    &,
    &--skeleton {
        min-height: 288px;
    }

    padding: 0;
    border: none;
    font-family: var(--g-font-family-primary);
    position: relative;

    @include breakpoint-sm {
        display: grid;
        grid-template-rows: 160px auto;
        grid-template-rows: auto;
        grid-template-columns: 1fr 1fr;
    }
}

.image {
    position: relative;
    height: 160px;

    img {
        border-top-left-radius: var(--s-border-radius-background-standard);
        border-top-right-radius: var(--s-border-radius-background-standard);
    }

    @include breakpoint-sm {
        height: 288px;

        img {
            border-top-right-radius: 0;
            border-bottom-left-radius: var(
                --s-border-radius-background-standard
            );
        }
    }
}

.past-trips,
.old-trips {
    img {
        filter: grayscale(1);
    }
}

.content {
    padding: var(--g-spacing-md);
    margin: auto 0;

    @include breakpoint-sm {
        padding: var(--g-spacing-xl);
    }
}

.body {
    display: flex;
    flex-direction: column;
    gap: calc(var(--g-spacing-xxs) * 2);
    margin-bottom: var(--g-spacing-md);
    align-items: flex-start;
}

.trip-name {
    a {
        text-decoration: none;
        color: inherit;
    }

    button {
        background: none;
        border: 0;
        padding: 0;
        font: inherit;
        color: inherit;
        text-align: left;
    }

    .focus-ring {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        border-radius: var(--s-border-radius-background-standard);
        outline: var(--g-border-width-sm) solid transparent;
        cursor: pointer;
    }

    a:focus-visible,
    button:focus-visible {
        outline: 0;

        .focus-ring {
            outline-color: var(--s-color-brand-primary-standard);
        }
    }
}

.old-trip-card {
    box-shadow: none;
}

.old-trip-text {
    color: var(--s-color-text-quiet);
}

.extension-container {
    flex: 1;
    min-width: 0;
}

.extension {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.rebook-button {
    display: block;
    margin-top: var(--g-spacing-md);
}
