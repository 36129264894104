@import "../../base-table/BaseTable.module.scss";

.collapsible:last-of-type {
    border-bottom: var(--g-border-width-xs) solid
        var(--s-color-separator-standard);
    margin-bottom: var(--g-spacing-xl);
}

.table {
    border-bottom: none;
    padding-bottom: 0;
}
