@use "../../styles/mixins" as *;
@include layer(ui) {
    .button {
        --inherited-icon-color: var(--icon-color-default);

        align-items: center;
        background: var(--background-default);
        border-color: var(--border-color-default);
        border-style: solid;
        box-sizing: border-box;
        color: var(--text-color-default);
        cursor: pointer;
        display: inline-flex;
        font-family: var(--g-font-family-primary);
        font-weight: var(--g-font-weight-regular);
        gap: var(--g-spacing-sm);
        text-decoration: none;
        transition-duration: var(--g-duration-xshort);
        transition-property: background-color, border-color, box-shadow, color,
            transform;
        transition-timing-function: ease;

        @media (prefers-reduced-motion: reduce), (forced-colors: active) {
            transition: none;
        }

        &[data-loading="true"] {
            cursor: not-allowed;
        }

        &:disabled:not([data-loading="true"]) {
            --inherited-icon-color: var(--icon-color-disabled);

            background: var(--background-disabled);
            border-color: var(--border-color-disabled);
            box-shadow: none;
            color: var(--text-color-disabled);
            cursor: not-allowed;
            transform: none;
        }

        &:focus-visible:not(:disabled) {
            --inherited-icon-color: var(--icon-color-focus);

            background: var(--background-focus);
            border-color: var(--border-color-focus);
            color: var(--text-color-focus);
            outline-color: var(--outline-color-focus);
            outline-style: solid;
            transition: none;

            .icon {
                transition: none;
            }
        }

        &:active:not(:disabled),
        &:hover:not(:disabled) {
            --inherited-icon-color: var(--icon-color-active);

            background: var(--background-active);
            border-color: var(--border-color-active);
            color: var(--text-color-active);
        }

        &-standard {
            font-size: var(--g-font-size-400);
            line-height: var(--g-line-height-400);
        }

        &-small {
            font-size: var(--g-font-size-300);
            line-height: var(--g-line-height-300);
        }

        .icon {
            align-items: center;
            color: var(--inherited-icon-color);
            display: inline-flex;
            transition-duration: var(--g-duration-xshort);
            transition-property: color;
            transition-timing-function: ease;

            @media (prefers-reduced-motion: reduce), (forced-colors: active) {
                transition: none;
            }
        }
    }

    .primary {
        border-radius: var(--c-button-primary-border-radius);
        border-width: var(--c-button-primary-border-width);
        box-shadow: var(--c-button-primary-shadow-offset-default)
            var(--border-color-default);
        transform: var(--c-button-primary-transform-default);
        margin-left: var(--c-button-primary-margin-offset);

        @media (prefers-reduced-motion: reduce), (forced-colors: active) {
            box-shadow: var(
                    --c-button-primary-shadow-offset-default-high-contrast
                )
                var(--border-color-default);
            transform: var(--c-button-primary-transform-default-high-contrast);
        }

        &:focus-visible:not(:disabled) {
            box-shadow: var(--c-button-primary-shadow-offset-focus)
                var(--border-color-focus);
            outline-offset: var(--c-button-primary-outline-offset-focus);
            outline-width: var(--c-button-primary-outline-width-focus);
            transform: var(--c-button-primary-transform-focus);

            @media (prefers-reduced-motion: reduce), (forced-colors: active) {
                box-shadow: var(
                        --c-button-primary-shadow-offset-focus-high-contrast
                    )
                    var(--border-color-focus);
                transform: var(
                    --c-button-primary-transform-focus-high-contrast
                );
            }
        }

        &:hover:not(:disabled) {
            box-shadow: var(--c-button-primary-shadow-offset-hover)
                var(--border-color-active);
            transform: var(--c-button-primary-transform-hover);

            @media (prefers-reduced-motion: reduce), (forced-colors: active) {
                box-shadow: var(
                        --c-button-primary-shadow-offset-hover-high-contrast
                    )
                    var(--border-color-active);
                transform: var(
                    --c-button-primary-transform-hover-high-contrast
                );
            }
        }

        &:active:not(:disabled) {
            box-shadow: var(--c-button-primary-shadow-offset-active)
                var(--border-color-active);
            transform: var(--c-button-primary-transform-active);

            @media (prefers-reduced-motion: reduce), (forced-colors: active) {
                box-shadow: var(
                        --c-button-primary-shadow-offset-active-high-contrast
                    )
                    var(--border-color-active);
                transform: var(
                    --c-button-primary-transform-active-high-contrast
                );
            }
        }

        &-standard {
            min-height: var(--g-size-md);
            padding-block: calc(
                var(--g-spacing-sm) - var(--c-button-primary-border-width)
            );
            padding-inline: calc(
                var(--g-spacing-lg) - var(--c-button-primary-border-width)
            );

            .icon {
                font-size: var(--c-button-primary-standard-icon-size);
            }
        }

        &-small {
            min-height: var(--g-size-sm);
            padding-block: calc(
                var(--g-spacing-xs) - var(--c-button-primary-border-width)
            );
            padding-inline: calc(
                var(--g-spacing-md) - var(--c-button-primary-border-width)
            );

            .icon {
                font-size: var(--c-button-primary-small-icon-size);
            }
        }
    }

    .secondary {
        border-radius: var(--c-button-secondary-border-radius);
        border-width: var(--c-button-secondary-border-width);

        &:focus-visible:not(:disabled) {
            outline-offset: var(--c-button-secondary-outline-offset-focus);
            outline-width: var(--c-button-secondary-outline-width-focus);
        }

        &:active:not(:disabled) {
            transform: var(--c-button-secondary-transform-active);
        }

        &-standard {
            min-height: var(--g-size-md);
            padding-block: calc(
                var(--g-spacing-sm) - var(--c-button-secondary-border-width)
            );
            padding-inline: calc(
                var(--g-spacing-lg) - var(--c-button-secondary-border-width)
            );

            .icon {
                font-size: var(--c-button-secondary-standard-icon-size);
            }
        }

        &-small {
            min-height: var(--g-size-sm);
            padding-block: calc(
                var(--g-spacing-xs) - var(--c-button-secondary-border-width)
            );
            padding-inline: calc(
                var(--g-spacing-md) - var(--c-button-secondary-border-width)
            );

            .icon {
                font-size: var(--c-button-secondary-small-icon-size);
            }
        }
    }

    .quiet {
        --spacing-horizontal: var(--c-button-quiet-spacing-horizontal-focus);

        border: none;
        border-radius: var(--c-button-quiet-border-radius);
        margin: 0 calc(var(--spacing-horizontal) * -1);
        padding: 0 var(--spacing-horizontal);
        position: relative;
        text-decoration: underline;
        text-decoration-thickness: var(--c-button-quiet-underline-width);
        &:visited {
            color: var(--text-color-default);
        }
        &:focus-visible:not(:disabled) {
            outline-offset: var(--c-button-quiet-outline-offset-focus);
            outline-width: var(--c-button-quiet-outline-width-focus);
        }

        &-standard {
            text-underline-offset: var(
                --c-button-quiet-standard-underline-spacing
            );

            .icon {
                font-size: var(--c-button-quiet-standard-icon-size);
            }
        }

        &-small {
            text-underline-offset: var(
                --c-button-quiet-small-underline-spacing
            );

            .icon {
                font-size: var(--c-button-quiet-small-icon-size);
            }
        }
    }

    .inverse {
        border-radius: var(--c-button-inverse-border-radius);
        border-width: var(--c-button-inverse-border-width);

        &:focus-visible:not(:disabled) {
            outline-offset: var(--c-button-inverse-outline-offset-focus);
            outline-width: var(--c-button-inverse-outline-width-focus);
        }

        &:active:not(:disabled) {
            transform: var(--c-button-inverse-transform-active);
        }

        &-standard {
            min-height: var(--g-size-md);
            padding-block: calc(
                var(--g-spacing-sm) - var(--c-button-inverse-border-width)
            );
            padding-inline: calc(
                var(--g-spacing-lg) - var(--c-button-inverse-border-width)
            );

            .icon {
                font-size: var(--c-button-inverse-standard-icon-size);
            }
        }

        &-small {
            min-height: var(--g-size-sm);
            padding-block: calc(
                var(--g-spacing-xs) - var(--c-button-inverse-border-width)
            );
            padding-inline: calc(
                var(--g-spacing-md) - var(--c-button-inverse-border-width)
            );

            .icon {
                font-size: var(--c-button-inverse-small-icon-size);
            }
        }
    }

    .visually-hidden {
        border: 0;
        clip: rect(0, 0, 0, 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }
}
