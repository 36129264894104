.late-add-banner {
    margin-top: var(--g-spacing-xl);
}

.passport-banner {
    margin-top: var(--g-spacing-md);
    margin-bottom: var(--g-spacing-xl);
}

.error {
    margin-bottom: var(--g-spacing-md);
    margin-top: var(--g-spacing-md);
}
