.header-wrapper {
    padding: 0 var(--g-spacing-xl) var(--g-spacing-xl);
}

.terms-wrapper {
    border-top: var(--g-border-width-xs) solid var(--s-color-separator-standard);
    border-bottom: var(--g-border-width-xs) solid
        var(--s-color-separator-standard);
    padding: var(--g-spacing-md) var(--g-spacing-xl);
}

.footer-wrapper {
    padding: var(--g-spacing-md) var(--g-spacing-xl) var(--g-spacing-xl);
}

.certification-wrapper,
.error-wrapper,
.checkbox-wrapper {
    padding: 0 var(--g-spacing-xl) var(--g-spacing-md);
}

.certification-wrapper {
    padding-top: var(--g-spacing-md);
    margin-bottom: var(--g-spacing-md);
}

.accept-wrapper {
    padding: 0 var(--g-spacing-xl) var(--g-spacing-xxl);
}
