@use "@wojo/ui/mixins" as *;

.dropdown {
    width: 100%;
    margin-bottom: var(--g-spacing-lg);

    @include breakpoint-md {
        width: 40%;
    }
}

.email {
    margin-top: var(--g-spacing-sm);
    color: var(--s-color-text-quiet);
}
