@use "@wojo/ui/mixins" as *;

.logos {
    display: flex;
    justify-content: center;
    gap: var(--g-spacing-xl);

    @include breakpoint-sm {
        gap: var(--g-spacing-xxl);
    }
}
