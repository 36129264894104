@use "@wojo/ui/mixins" as *;

.itinerary-wrapper {
    margin-bottom: var(--g-spacing-xl);
}

.itinerary-heading {
    margin-bottom: var(--g-spacing-md);

    @include breakpoint-md {
        margin-bottom: var(--g-spacing-lg);
    }
}
